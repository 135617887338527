<template>
  <v-overlay :value="overlay" z-index="1000">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<script>
import { handleOAuth } from "../modules/bank-oauth-handler";

export default {
  data: () => ({
    overlay: false,
  }),
  name: "callback",
  async created() {
    this.overlay = true;
    const redirectUrl = await handleOAuth(this.$route.meta.props.to);
    if (this.$cookie.get("source") === "leanpay" && !redirectUrl.includes("revolut")) {
      window.location.href = "https://www.leanpay.portal.bankszamlakivonat.hu" + redirectUrl
    }
    if (redirectUrl.startsWith("https://"))
    {
      window.location.href = redirectUrl
    }
    await this.$router.push({ path: redirectUrl });
  },
};
</script>
